import { REACT_APP_FITVIEW_URL } from "../constants/Constants";
import { v4 as uuidv4 } from "uuid";

export const isValidEmail = (email) => {
  const checkEmailValidation =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return checkEmailValidation.test(email);
};

export const makeMobileUrl = ({ 
  productHandle, 
  shopDomain, 
  shopify_redirect_url, 
  isCustom, 
  email, 
  name, 
  callbackUrl 
}) => {
  
  const params = new URLSearchParams({
    product_name: productHandle,
    shopify_redirect: shopify_redirect_url,
    shop_domain: shopDomain,
    ...(email && { email: encodeURIComponent(email) }),
    ...(callbackUrl && { callback_url: callbackUrl }),
  });

  if (isCustom) {
    params.append("scan_type", "clothing_custom_scan");
    if (name) params.append("name", name);
  }

  return `${REACT_APP_FITVIEW_URL}/?${params.toString()}`;
};


export function generateUuid() {
  return uuidv4();
}
export function updateQRCodeInUrl(url, qrCode) {
  const parsedUrl = new URL(url);
  if (parsedUrl.searchParams.has("qr_code")) {
    parsedUrl.searchParams.set("qr_code", qrCode);
  } else {
    parsedUrl.searchParams.append("qr_code", qrCode);
  }
  return parsedUrl.toString(); 
}



